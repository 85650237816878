import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import { HealthHistoryType } from '@shared/utils'

import api from './index'

const fabric = (type) => ({
  list: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/${type}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  create: (patientId, data) =>
    api.post(`/patients/${patientId}/${type}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  edit: (patientId, id, data) =>
    api
      .patch(`/patients/${patientId}/${type}/${id}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  remove: (patientId, id) =>
    api.delete(`/patients/${patientId}/${type}/${id}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
})

const HealthHistoryApi = {
  allergies: fabric(HealthHistoryType.Allergies),
  conditions: fabric(HealthHistoryType.Conditions),
  medications: fabric(HealthHistoryType.Medications),
  surgeries: fabric(HealthHistoryType.Surgeries),
  immunizations: fabric(HealthHistoryType.Immunizations),
  vitals: {
    weight: fabric(HealthHistoryType.Vitals),
    height: fabric(HealthHistoryType.Vitals),
    bp: fabric(HealthHistoryType.Vitals),
  },
  labs: {
    rpr: fabric(HealthHistoryType.Labs),
    creatinine: fabric(HealthHistoryType.Labs),
    viral_load: fabric(HealthHistoryType.Labs),
    cd4: fabric(HealthHistoryType.Labs),
  },
}

export default HealthHistoryApi
