import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useQuestionnaires() {
  const me = useMe()

  return useQuery({
    queryKey: QK.patients.id(me.id).questionnaires,
    queryFn: () => API.patients.id(me.id).questionnaires.list(),
  })
}

export function useQuestionnairesUpdate() {
  const me = useMe()

  return useMutation({
    mutationFn: (data) => API.patients.id(me.id).questionnaires.update(data),
    onSuccess: (data) => queryClient.setQueryData(QK.patients.id(me.id).questionnaires, data),
  })
}
