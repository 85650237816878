import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useMe } from '@shared/providers/src/MeProvider'

import { Badge, Drawer, ListItem, ListItemButton, ListItemText, MenuItem, Stack, useMediaQuery } from '@mui-components'
import FormHeader from '@components/Header/Form'
import Threads from '@components/Threads'

import { useDrawer } from '../Header.hooks'

/**
 * Messages component that displays a menu item with unread message count.
 * On smaller screens, it opens a drawer containing the MessagesList.
 */
export default function Messages() {
  const me = useMe()
  const [drawer, setDrawer] = useDrawer()
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true })

  return (
    <>
      {mdDown && <MessagesList open={drawer === 'messages'} onClose={() => setDrawer(undefined)} />}
      <ListItem disablePadding>
        <ListItemButton
          {...(mdDown ? { onClick: () => setDrawer('messages') } : { component: RouterLink, to: '/app/messages' })}
          data-testid="messages-menu-item"
          aria-label="Navigate to Messages"
          role="menuitem"
        >
          <ListItemText primary="Messages" />
          <Badge
            color="primary"
            badgeContent={me.totalUnreadMessageCount}
            max={99}
            data-testid="unread-messages-count-badge"
            aria-label={me.totalUnreadMessageCount > 0 ? `${me.totalUnreadMessageCount} unread messages` : 'No unread messages'}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}

/**
 * MessagesList component that displays the messages in a drawer.
 */
function MessagesList({ open, onClose }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant="temporary"
      anchor="left"
      sx={{ zIndex: 1300, '& .MuiDrawer-paper': { maxWidth: 320, width: '100%' } }}
      PaperProps={{
        'data-testid': 'messages-drawer',
        role: 'dialog',
        'aria-labelledby': 'messages-drawer-title',
        'aria-describedby': 'messages-drawer-description',
      }}
    >
      <Stack>
        <FormHeader title="Messages" onClose={onClose} id="messages-drawer-title" aria-describedby="messages-drawer-description" />
        <Threads newPageOnSelect />
      </Stack>
    </Drawer>
  )
}
