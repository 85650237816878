import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useGiveConsent() {
  const me = useMe()

  return useMutation({
    mutationFn: (data) => API.patients.id(me.id).consents.agree(data),
    onSuccess: (updatedData) => {
      // merge current user settings with updated user settings
      return queryClient.setQueryData(QK.me(me.id).details, (data) => {
        const currentSettings = mapSettings(data.userSettings)
        const updatedSettings = mapSettings(updatedData)
        const settings = { ...currentSettings, ...updatedSettings }
        return { ...data, userSettings: Object.values(settings) }
      })
    },
  })
}

const mapSettings = (settings = []) => settings.reduce((acc, s) => ({ ...acc, [s.id]: s }), {})
