import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useSendVerification() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => API.patients.id(id).verifications.send(data),
  })
}

export function usePatientUpdatePhotoId() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => API.patients.id(id).photoID.create(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).photoID }),
  })
}
