import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useInsurances(options = {}) {
  const { id } = useMe()
  return useQuery({
    queryKey: QK.patients.id(id).insurances.list(),
    queryFn: () => API.patients.id(id).insurances.list(),
    ...options,
  })
}

export function useInsuranceCreation() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => API.patients.id(id).insurances.create(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).insurances.lists }),
  })
}
