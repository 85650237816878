import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { mapCache, QK } from '@shared/utils'

export function useInsurance(insuranceId, options = {}) {
  const { id } = useMe()

  return useQuery({
    queryKey: QK.patients.id(id).insurances.id(insuranceId).details,
    queryFn: () => API.patients.id(id).insurances.id(insuranceId).details(),
    ...options,
  })
}

export function useInsuranceRemove(insuranceId) {
  const { id } = useMe()
  return useMutation({
    mutationFn: () => API.patients.id(id).insurances.id(insuranceId).remove(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).insurances.lists }),
  })
}

export function useInsuranceUpdate(insuranceId) {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => API.patients.id(id).insurances.id(insuranceId).update(data),
    // Remove front and back images from the response because they are always null
    onSuccess: async ({ front, back, ...data }, variables) => {
      queryClient.setQueryData(QK.patients.id(id).insurances.id(insuranceId).details, (prev) => {
        // update the cache with the new data to increase performance
        // front and back pictures must be updated only if new values are not empty
        return {
          ...prev,
          ...data,
          front: variables.front_base64_image || prev.front,
          back: variables.back_base64_image || prev.back,
        }
      })

      // Check if the insurance is in the ANY list of insurances of that patient and update it
      queryClient.setQueriesData(
        { queryKey: QK.patients.id(id).insurances.lists },
        mapCache((item) => (item.id === data.id ? data : item))
      )
    },
  })
}
