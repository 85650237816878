import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useTreatmentUpdate() {
  const { id } = useMe()

  return useMutation({
    mutationFn: (data) => API.patients.id(id).treatmentTypes.update(data),
    onSuccess: (data) =>
      queryClient.setQueriesData({ queryKey: QK.patients.id(id).treatments.lists }, (oldData = []) =>
        oldData.filter((treatment) => treatment.id !== data.id).concat(data)
      ),
  })
}

export function usePatientAvailableServiceLines() {
  const me = useMe()
  const patientId = me.id

  const { data } = useQuery({
    queryKey: QK.patients.id(patientId).availableServiceLines.list(),
    queryFn: () => API.patients.id(patientId).availableServices(),
    select: (data) => data.map((s) => s.name),
  })

  return data
}
