import { useInfiniteQuery } from '@tanstack/react-query'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import ConversationsApi from '@shared/services/src/Conversations.api'
import { pageParam, QK } from '@shared/utils'

const DEFAULT_LIMIT = 20

export default function useConversations(query, settings = {}) {
  const fullQuery = { limit: DEFAULT_LIMIT, ...query }
  const userId = fullQuery.user_id
  const limit = fullQuery.limit

  usePubNub(
    `user_${userId}`,
    ({ action }) => {
      if (action !== Channels.NewMessage) return null
      queryClient.invalidateQueries({ queryKey: QK.conversations.list(fullQuery) })
    },
    { enabled: settings.subscribe ?? true }
  )

  usePubSub(
    PubSubEvents.ConversationMessageSent,
    () => {
      queryClient.invalidateQueries({ queryKey: QK.conversations.list(fullQuery) })
    },
    { enabled: settings.subscribe ?? true }
  )

  return useInfiniteQuery({
    queryKey: QK.conversations.list(fullQuery),
    queryFn: ({ pageParam }) => ConversationsApi.conversations({ ...fullQuery, offset: pageParam * limit }, settings.config),
    staleTime: 0,
    select: (data) => {
      const conversations = data.pages.flat()
      if (!settings.search) return conversations

      return conversations.filter((thread) => {
        const users = thread.users.filter((u) => u.id !== userId)
        const threadName = users.map((u) => u.fullname).join(', ')
        return threadName.toLowerCase().includes(settings.search.toLowerCase())
      })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })
}
