import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import AddressesSharedApi from '@shared/services/src/Addresses.api'
import API from '@shared/services/src/API'

export function useZipCodeInfo() {
  return useMutation({
    mutationFn: (zip) => AddressesSharedApi.lookupByZipCode({ zip }),
  })
}

export function useCBOs() {
  const me = useMe()
  return useMutation({
    mutationFn: ({ zip, county }) => API.patients.id(me.id).cbos.list({ zipcode: zip, county }),
  })
}
