import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePatientUpdateProfilePic() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => API.patients.id(id).profilePicture.create(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.me(id).details }),
  })
}

export function usePatientDeleteProfilePic() {
  const { id } = useMe()
  return useMutation({
    mutationFn: () => API.patients.id(id).profilePicture.remove(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.me(id).details }),
  })
}
