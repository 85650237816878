import { useMemo } from 'react'

import {
  AboutMe,
  Address,
  HealthAssistance,
  HealthHistory,
  Insurance,
  Profile,
  SexualHealth,
  useProfileMissingErrors,
} from '@pages/Profile'
import { Badge, Box, Dialog, ListItem, ListItemButton, ListItemText, Stack, useMediaQuery } from '@mui-components'
import FormHeader from '@components/Header/Form'
import { SlideUp } from '@components/Transitions'

import { useDrawer } from '../Header.hooks'
import Menu from './components/Menu'
import ProfilePic from './components/ProfilePic'
import { useProfileItem } from './EditProfile.hooks'

/**
 * EditProfile component that allows users to update their profile information.
 * It includes a menu item with a badge indicating the number of profile errors.
 * On smaller screens, it opens a full-screen dialog containing the EditProfileMenu.
 */
export default function EditProfile() {
  const [drawer, setDrawer] = useDrawer()
  const handleClose = () => setDrawer(undefined)

  const { data } = useProfileMissingErrors()
  const count = useMemo(() => Object.values(data).reduce((sum, c) => sum + c, 0), [data])

  return (
    <>
      <Dialog
        fullScreen
        open={drawer === 'profile'}
        onClose={handleClose}
        TransitionComponent={SlideUp}
        aria-labelledby="edit-profile-dialog-title"
        aria-describedby="edit-profile-dialog-description"
        role="dialog"
        aria-modal="true"
      >
        <EditProfileMenu onClose={handleClose} />
      </Dialog>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => setDrawer('profile')}
          data-testid="my-profile-menu-item"
          aria-label="Open profile editing dialog"
          role="menuitem"
        >
          <ListItemText primary="My profile" />
          <Badge
            color="error"
            badgeContent={count}
            max={99}
            data-testid="field-errors-count-badge"
            aria-label={count > 0 ? `${count} profile fields have errors` : 'All profile fields are complete'}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}

/**
 * EditProfileMenu component that displays various profile sections.
 * It adapts its layout based on screen size and the selected profile item.
 */
function EditProfileMenu({ onClose }) {
  const [profileItem, setProfileItem] = useProfileItem()

  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTablet = !isPhone

  const closeProfileItem = () => {
    if (isTablet) return
    setProfileItem(undefined)
  }

  return (
    <Stack>
      <FormHeader
        title="Update My Profile"
        onClose={() => {
          onClose()
          closeProfileItem()
        }}
        data-testid="my-profile-dialog-header"
        id="edit-profile-dialog-title"
        aria-describedby="edit-profile-dialog-description"
      />
      <Stack spacing={2} sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', py: 2 }}>
        <ProfilePic />
        <Stack direction="row" spacing={2}>
          {(isTablet || (isPhone && !profileItem)) && <Menu />}
          <Box sx={{ pt: 1, px: isPhone ? 2 : 0, width: '100%', overflow: 'hidden' }} role="region" aria-labelledby="profile-section">
            {profileItem === 'profile' && <Profile onClose={closeProfileItem} />}
            {profileItem === 'address' && <Address onClose={closeProfileItem} />}
            {profileItem === 'insurance' && <Insurance onClose={closeProfileItem} />}
            {profileItem === 'health-assistance' && <HealthAssistance onClose={closeProfileItem} />}
            {profileItem === 'health-history' && <HealthHistory onClose={closeProfileItem} />}
            {profileItem === 'sexual-health' && <SexualHealth onClose={closeProfileItem} />}
            {profileItem === 'about' && <AboutMe onClose={closeProfileItem} />}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
