import { useMemo } from 'react'

import useCurrentUserId from '@shared/hooks/src/useCurrentUserId'
import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useQuery from '@shared/hooks/src/useQuery'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import MeSharedProvider from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import UsersSharedApi from '@shared/services/src/Users.api'
import { QK } from '@shared/utils'

/**
 * Wraps shared MeProvider with a query to fetch the current user,
 * and subscribes to PubNub events for the current user.
 */
export default function MeProvider({ children }) {
  const userId = useCurrentUserId()

  const photoQuery = useQuery({
    queryKey: QK.patients.id(userId).photoID,
    queryFn: () => API.patients.id(userId).photoID.details(),
  })
  const meQuery = useQuery({
    queryKey: QK.me(userId).details,
    queryFn: UsersSharedApi.me,
  })

  useQueryEvents(meQuery, {
    onSuccess: ({ id }) => {
      // Identify user in analytics
      if (window.analytics) {
        window.analytics.identify(id)
      }

      queryClient.prefetchQuery({
        queryKey: QK.patients.id(id).insurances.list(),
        queryFn: () => API.patients.id(id).insurances.list(),
      })
    },
  })

  usePubNub(
    `user_${userId}`,
    ({ action, attributes }) => {
      // If a new message is received, update the unread messages count
      if (action === Channels.NewMessage) {
        queryClient.setQueryData(QK.me(userId).details, (prev) => ({
          ...prev,
          totalUnreadMessageCount: attributes.total_unread_message_count,
        }))
      }
      if ([Channels.ReassignedPatient, Channels.EmailVerified].includes(action)) {
        queryClient.invalidateQueries({ queryKey: QK.me(userId).details })
      }
    },
    { enabled: Boolean(userId) }
  )

  const data = useMemo(() => {
    if (!photoQuery.data || !meQuery.data) return meQuery.data

    return {
      ...meQuery.data,
      photoId: photoQuery.data.photoId ? `data:image/*;base64,${photoQuery.data.photoId}` : null,
    }
  }, [photoQuery.data, meQuery.data])

  return <MeSharedProvider value={data}>{children}</MeSharedProvider>
}
